<template>
  <section class="h-services" ref="scroll3">
    <div class="h-services__bg"></div>
    <div class="container">
      <ScreenTitle secondary>Services</ScreenTitle>
      <div class="h-services__content flex flex-col">
        <ServiceCard
          v-for="(item, index) in servs"
          :key="index"
          :index="index+1"
          :id="`s${index+1}`"
          :title="item.title"
          :text="item.text"
          :visible="visible && visibleCnt === 1 && blocks[index].cnt == 1 && blocks[index].visible"
          :hide="(visibleCnt === 1 || visibleCnt === 0) && blocks[index].cnt === 0"
        ></ServiceCard>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import ScreenTitle from "@/components/common/ScreenTitle.vue";
import ServiceCard from "@/components/common/ServiceCard.vue";
import { SERVICES } from "@/constant/services.js";
import {isElementVisible} from "@/visibility";

const props = defineProps({
  visibleCnt: Number,
  hide: Boolean,
  visible: Boolean
})

const blocks = ref(Array.from({ length: 5 }, () => ({ id: null, visible: false, cnt: 0 })));

const servs = ref(SERVICES);

const handleScroll = () => {
  blocks.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (!!element) {
      const isVisible = isElementVisible(element, 0);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt = block.cnt + 1;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
};

onMounted(() => {
  blocks.value.forEach((block, index) => {
    block.id = `s${index + 1}`;
  });
  handleScroll();
  window.addEventListener('scroll', handleScroll);
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
.h-services {
  padding: 60px 0 90px;
  position: relative;
  &__bg {
    background: $dark-bg;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__content {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .h-services {
    padding: 40px 0 54px;
  }
}
</style>