<template>
  <section class="home-banner">
    <div class="home-banner__content flex flex-col items-center">
      <div class="flex flex-col items-center">
        <div>
          <div class="text-img-anim" :class="{'anim': visible, 'hide': hide}">
            <p class="desk text-xxl font-700 roboto-flex uppercase">Shaping the Future of</p>
            <p class="mobile text-xxl font-700 roboto-flex uppercase">Shaping the</p>
          </div>
          <div class="text-img-anim mobile" :class="{'anim': visible, 'hide': hide}">
            <p class="text-xxl font-700 roboto-flex uppercase">Future of Digital</p>
          </div>
          <div class="text-img-anim" :class="{'anim': visible, 'hide': hide}">
            <p class="desk text-xxl font-700 roboto-flex uppercase">Digital Excellence</p>
            <p class="mobile text-xxl font-700 roboto-flex uppercase">Excellence</p>
          </div>
        </div>
        <p class="text-m font-400">Discover the power of synergy in the tech sphere with us.</p>
      </div>
      <Button iconName="message" secondary @click="isClicked = true">Get in Touch</Button>
    </div>
    <Modal v-if="isClicked" @close="isClicked = false"></Modal>
  </section>
</template>

<script setup>
import { ref } from "vue";
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';

const props = defineProps({
  visible: Boolean,
  hide: Boolean
})

const isClicked = ref(false);
</script>

<style lang="scss" scoped>
.home-banner {
  &__content {
    color: $white;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 272px 0 312px;
    text-align: center;
    gap: 22px;
    & > div {
      gap: 16px;
    }
    & p {
      &.text-m {
        opacity: 0.82;
      }
    }
    .text-img-anim .mobile, .text-img-anim.mobile {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .home-banner {
    &__content {
      padding: 200px 0 230px;
      max-width: 600px;
    }
  }
}

@media (max-width: 768px) {
  .home-banner {
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
      padding: 0 22px;
      max-width: 500px;
      width: 100%;
      align-items: flex-start;
      gap: 32px;
      margin: 0;
      & > div {
        align-items: flex-start;
        text-align: left;
      }
    }
  }
}

@media (max-width: 450px) {
  .home-banner {
    &__content {
      .text-img-anim .desk {
        display: none;
      }
      .text-img-anim.mobile, .text-img-anim .mobile {
        display: block;
      }
    }
  }
}
</style>