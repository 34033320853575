export const SERVICES = [
  {
    title: "Digital Marketing",
    text: "Elevating your online presence through comprehensive strategies including SEO, content marketing, social media management, and pay-per-click advertising."
  },
  {
    title: "Software Development",
    text: "Crafting custom software solutions, from dynamic CRM systems to innovative applications, tailored to meet your business's unique needs and challenges."
  },
  {
    title: "Advertising Solutions",
    text: "Delivering impactful advertising campaigns across various platforms, blending creativity with analytics to drive brand awareness and engagement."
  },
  {
    title: "Data Analytics&Insights",
    text: "Harnessing the power of data to provide actionable insights, improve decision-making, and optimize your digital strategy for maximum effectiveness."
  },
  {
    title: "Technology Consulting",
    text: "Offering expert guidance on digital transformation, IT infrastructure, and technology strategy to ensure your business is future-ready."
  }
]