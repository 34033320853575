<template>
  <div
    @click="scrollToTop"
    ref="scrollToTopBtn"
    class="scroll-arrow flex items-center justify-center"
    :class="{'show': isShow}"
    id="scrollToTopBtn"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
      <path d="M10.5 1L1.5 9.99973L10.5 19" :stroke="'white'" :stroke-opacity="1" stroke-width="2"/>
    </svg>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const scrollToTopBtn = ref(null);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

const isShow = ref(false);

const handleScroll = () => {
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    isShow.value = true;
  } else {
    isShow.value = false;
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};
</script>

<style lang="scss" scoped>
.scroll-arrow {
  position: fixed;
  right: 80px;
  bottom: 80px;
  z-index: 1000;
  display: none;
  border-radius: 66px;
  font-size: 74px;
  width: 1em;
  height: 1em;
  cursor: pointer;
  background: $dark;
  transform: rotate(90deg);
  opacity: 0.92;
  &.show {
    display: flex;
  }
}

@media (max-width: 768px) {
  .scroll-arrow {
    font-size: 54px;
    right: 30px;
    bottom: 40px;
  }
}
</style>