<template>
  <div class="menu">
    <div class="menu__bg">
      <div class="bg1 absolute-item"></div>
      <div class="bg2 absolute-item"></div>
    </div>
    <div class="menu__content absolute-item flex flex-col">
      <div class="menu__head flex items-center justify-between">
        <Button primary iconName="chat" single @click="isModalOpen = true"></Button>
        <Logo />
        <ControlBtn isOpen @click="$emit('close')" />
      </div>
      <nav class="flex flex-col">
        <p
          v-for="(item, index) in navs"
          :key="index"
          class="text-mxl font-400"
          @click="handleLinkClick(item), $emit('close')"
        >{{ item.label }}</p>
      </nav>
      <Info />
    </div>
    <Modal v-if="isModalOpen" @close="isModalOpen = false" />
  </div>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import Button from "@/components/ui/Button.vue";
import Logo from "@/components/common/Logo.vue";
import ControlBtn from "@/components/menu/ControlBtn.vue";
import Info from "@/components/menu/Info.vue";
import Modal from "@/components/ui/Modal.vue";
import { NAVS } from "@/constant/nav";
import { useNavigationStore } from '@/store/navigation';
import { useRouter } from 'vue-router'

const navs = ref(NAVS);
const isModalOpen = ref(false);

if(navs.value.length === 5) {
  navs.value.unshift({label: 'Home', ref: 'first'})
}

const router = useRouter();
const navigationStore = useNavigationStore();
const handleLinkClick = (link) => {
  if (link.ref) {
    navigationStore.setBlockIndex(link.ref)
    navigationStore.setBlockClicked(true)
    if(router.currentRoute.value.name !== 'home') router.push('/')
  }
}

onUnmounted(() => {
  navs.value.splice(0, 1);
})
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 100%;
  animation: menu-anim 0.8s ease forwards;
  z-index: 99;

  .absolute-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__bg {
    position: relative;
    height: 100vh;
    z-index: 1;
    .bg1 {
      background: url('@/assets/img/banner-mob.png') no-repeat center;
      background-size: cover;
    }
    .bg2 {
      background: rgba(22, 22, 22, 0.50);
      backdrop-filter: blur(24px);
    }
  }
  &__content {
    z-index: 3;
    padding: 15px 22px 26px 22px;
  }
  &__head {
    padding-bottom: 15px;
  }
}

nav {
  margin: 60px 0 60px;
  color: $white;
  overflow-y: scroll;
  p {
    opacity: 0.8;
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    &:not(&:first-child) {
      padding-top: 28px;
    }
    &:first-child {
      opacity: 1;
    }
  }
}
</style>