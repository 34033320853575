<template>
  <header>
    <div class="header-content flex justify-between items-center">
      <Logo />
      <div class="actions flex justify-between items-center">
        <Navbar />
        <Button primary iconName="chat" @click="mods.isModalOpen = true">Contacts</Button>
        <ControlBtn @click="mods.isMenuOpen = true" />
      </div>
    </div>
    <Menu v-if="mods.isMenuOpen" @close="mods.isMenuOpen = false" />
    <Modal v-if="mods.isModalOpen" @close="mods.isModalOpen = false" />
  </header>
</template>

<script setup>
import { ref, watch } from "vue";
import Logo from '@/components/common/Logo.vue';
import Navbar from '@/components/common/Navbar.vue';
import Button from '@/components/ui/Button.vue';
import ControlBtn from '@/components/menu/ControlBtn.vue';
import Menu from '@/components/menu/Menu.vue';
import Modal from '@/components/ui/Modal.vue';

const mods = ref({
  isMenuOpen: false,
  isModalOpen: false
})

document.body.classList.remove('no-scroll');
watch(mods, () => {
  if(mods.value.isMenuOpen || mods.value.isModalOpen) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
}, {deep: true})
</script>

<style lang="scss" scoped>
header {
  border-bottom: 1px solid rgba(203, 203, 203, 0.24);
}
.header-content {
  padding: 15px 52px;
  max-width: 1440px;
  margin: 0 auto;
  .actions {
    gap: 72px;
  }
}

@media (max-width: 1024px) {
  .header-content {
    padding: 15px 30px;

    .actions {
      gap: 24px;
    }
  }
}

@media (max-width: 768px) {
  header {
    border-bottom: none;
  }
  .header-content {
    padding: 15px 22px;

    .actions {
      gap: 12px;
    }
  }
}
</style>