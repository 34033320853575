<template>
  <div class="foot flex flex-col">
    <div class="foot_top flex items-center justify-between">
      <a class="text-xs font-400 no-underline" href="mailto:info@rexusint.com">info@rexusint.com</a>
      <a href="https://www.linkedin.com/company/rexus-ltd/about/" target="_blank" class="no-underline">
        <span class="text-xs lh2 font-400">LinkedIn </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path opacity="0.72" d="M4.26659 11.9999L3.33325 11.0666L9.73325 4.66659H3.99992V3.33325H11.9999V11.3333H10.6666V5.59992L4.26659 11.9999Z" fill="white"/>
        </svg>
      </a>
    </div>
    <div class="flex items-center justify-between">
      <p class="text-xs font-400">© 2024 —  by Rexus International Ltd.</p>
      <RouterLink to="/privacy" class="no-underline text-xs font-400">Privacy Policy</RouterLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.foot {
  margin-top: auto;
  color: $white;
  gap: 14px;
  &_top {
    a {
      transition: all .3s ease;
      cursor: pointer;
      &:hover {
        color: $yellow !important;
        svg path {
          fill: $yellow;
          opacity: 1;
        }
      }
    }
  }
  a, p {
    opacity: 0.72;
  }
  a, a:focus, a:visited {
    color: $white;
  }
}
</style>