<template>
  <RouterLink to="/">
    <div class="main-logo">
      <img src="@/assets/icons/logo.svg" alt="main logo" />
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.main-logo {
  img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .main-logo {
    width: 75px;
  }
}
</style>