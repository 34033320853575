<template>
  <swiper
    ref="swiperRef"
    :slides-per-view="1"
    :space-between="200"
    @swiper="onSwiper"
    :modules="[Navigation]"
  >
    <swiper-slide v-for="slide in slides" :key="slide">
      <div class="slide flex flex-col">
        <p class="roboto-flex text-xxl lh-2 italic opacity-92" :class="isThin ? 'font-300' : 'font-400'">"{{ slide.text }}"</p>
        <p class="text-l font-700 opacity-92">{{ slide.author }}</p>
      </div>
    </swiper-slide>
    <div class="slider-controls flex items-center">
      <SliderControl
        @click="goPrev"
        :disabled="swiperInstance?.activeIndex === 0"
      ></SliderControl>
      <div class="slider-controls__nums">
        <p class="text-l font-400">0{{ swiperInstance?.activeIndex+1 }}<span class="text-m font-400 opacity-92">/0{{ slides.length }}</span></p>
      </div>
      <SliderControl
        @click="goNext"
        btnType="next"
        :disabled="swiperInstance?.activeIndex === 2"
      ></SliderControl>
    </div>
  </swiper>
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import SliderControl from "@/components/common/SliderControl.vue";
import 'swiper/css';

const props = defineProps({
  slides: Array,
  isThin: Boolean
})

const swiperInstance = ref(null);

const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  
}
.slide {
  max-width: 920px;
  margin-right: 5px;
  gap: 26px;
}
.slider-controls {
  margin-top: 60px;
  gap: 24px;
  justify-content: flex-end;
  &__nums {
    color: $black;
    span {
      color: $gray;
    }
  }
}

@media (max-width: 768px) {
  .slide {
    gap: 32px;
    .text-xxl {
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 1.08px;
    }
  }
  .slider-controls {
    margin-top: 40px;
    .text-l {
      font-size: 24px;
      line-height: 34px;
    }
    .text-m {
      line-height: 28px;
    }
  }
}
</style>