export const CITATIONS = [
  {
    text: "Focusing on customer experience at every step of the buyer journey is critical for business success.",
    author: " - Yamini Rangan, CEO of HubSpot​​."
  },
  {
    text: "Providing customers with unique value helps businesses stand out from their competitors.",
    author: " - Reda Elmardi, CEO of Strong Chap​​."
  },
  {
    text: "Being able to adjust and evolve with market changes is crucial for long-term sustainability.",
    author: " - Tom Winter, Founder of DevSkiller​​."
  }
]