<template>
  <section class="h-feature">
    <div class="container">
      <ScreenTitle center>Our Feature</ScreenTitle>
      <div class="h-feature__content flex flex-wrap">
        <FeatureCard
          v-for="(item, index) in features"
          :key="index"
          :img="item.icon"
          :id="`f${index+1}`"
          :title="item.title"
          :text="item.text"
          :visible="visible && visibleCnt === 1 && blocks[index].cnt == 1 && blocks[index].visible"
          :hide="(visibleCnt === 1 || visibleCnt === 0) && blocks[index].cnt === 0"
        ></FeatureCard>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { FEATURES } from "@/constant/feature.js";
import ScreenTitle from "@/components/common/ScreenTitle.vue";
import FeatureCard from "@/components/common/FeatureCard.vue";
import {isElementVisible} from "@/visibility";

const props = defineProps({
  visibleCnt: Number,
  hide: Boolean,
  visible: Boolean
})

const blocks = ref(Array.from({ length: 5 }, () => ({ id: null, visible: false, cnt: 0 })));

const handleScroll = () => {
  blocks.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (!!element) {
      const isVisible = isElementVisible(element, 0);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt = block.cnt + 1;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
};

const features = ref(FEATURES);

onMounted(() => {
  blocks.value.forEach((block, index) => {
    block.id = `f${index + 1}`;
  });
  handleScroll();
  window.addEventListener('scroll', handleScroll);
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
.h-feature {
  &__content {
    margin-top: 60px;
    row-gap: 62px;
    column-gap: 71px;
  }
}

@media (max-width: 1280px) {
  .h-feature {
    &__content {
      column-gap: 50px;
    }
  }
}

@media (max-width: 768px) {
  .h-feature {
    &__content {
      margin-top: 36px;
      gap: 40px;
      padding: 0 29px;
    }
  }
}
</style>