export const FEATURES = [
  {
    title: "Discovery",
    icon: "1.jpg",
    text: "We start by understanding your unique needs and crafting a strategic plan that aligns with your goals."
  },
  {
    title: "Innovation",
    icon: "2.jpg",
    text: "Our cross-disciplinary expertise brings forth creative, tech-driven solutions tailored to set you apart."
  },
  {
    title: "Execution",
    icon: "3.jpg",
    text: "We seamlessly integrate our solutions into your operations, ensuring smooth transitions and immediate benefits."
  },
  {
    title: "Optimization",
    icon: "4.jpg",
    text: "Continuous improvement is key; we refine and adjust to keep your solutions at the forefront of efficiency."
  },
  {
    title: "Partnership",
    icon: "5.jpg",
    text: "Beyond projects, we're your long-term allies, adapting and growing with your business to ensure enduring success."
  }
]