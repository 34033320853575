<template>
  <section class="h-citation">
    <div class="container">
      <ScreenTitle>Citations</ScreenTitle>
      <div class="h-citation__content">
        <Slider :slides="cits" isThin></Slider>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import ScreenTitle from '@/components/common/ScreenTitle.vue';
import Slider from '@/components/common/Slider.vue';
import { CITATIONS } from "@/constant/citation.js";

const cits = ref(CITATIONS);
</script>

<style lang="scss">
.h-citation {
  padding: 70px 0 110px;
  &__content {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .h-citation {
    padding: 40px 0 60px;
    &__content {
      margin-top: 40px;
    }
  }
}
</style>