import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia';

import './assets/scss/style.scss'
import './assets/scss/font.scss'
import './assets/scss/text.scss'
import './assets/scss/media.scss'
import './assets/scss/animation.scss'

const pinia = createPinia();

createApp(App).use(router).use(pinia).mount('#app')
