export const PARTNERS = [
  {
    img: "1.jpg",
    title: "Strategic Alignment",
    text: "Selecting partners whose vision and objectives align with ours, ensuring a harmonious and productive collaboration geared towards common goals."
  },
  {
    img: "2.jpg",
    title: "Innovation Through Collaboration",
    text: "Leveraging the strengths of each partner to foster innovation, we create solutions that are greater than the sum of their parts, driving forward the boundaries of what's possible."
  },
  {
    img: "3.jpg",
    title: "Long-Term Relationships",
    text: "Committing to the long haul, we focus on building enduring relationships that evolve with the changing needs of our partners and the market, ensuring sustained success and growth for all involved."
  }
]