export const PRODUCTS = [
  {
    img: "1.png",
    title: "CRM Systems",
    text: "Advanced Customer Relationship Management solutions designed to streamline sales, marketing, and customer service processes, enhancing engagement and loyalty."
  },
  {
    img: "2.png",
    title: "Marketing Tech Tools",
    text: "Unleash CRM power & marketing automation. Streamline sales, manage affiliates, optimize campaigns – all in one place. Maximize ROI."
  },
  {
    img: "3.png",
    title: "Analytical Platforms",
    text: "Sophisticated data analysis tools that provide deep insights into market trends, consumer behavior, and website traffic, empowering businesses with data-driven strategies."
  },
  {
    img: "4.png",
    title: "Digital Content Platforms",
    text: "Innovative platforms for creating, managing, and distributing digital content, enabling brands to engage audiences effectively across multiple channels."
  },
  {
    img: "5.png",
    title: "CRM Enhancements",
    text: "Supercharge your CRM with AI sales forecasting, segmentation & personalization. Streamline workflows."
  }
]