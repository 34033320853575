<template>
  <div
    class="input"
    :class="{'error': isError, 'textarea': textarea}"
  >
    <textarea
      v-if="textarea"
      :placeholder="placeholder ?? ''"
      :value="modelValue"
      @input="updateModelValue($event.target.value)"
    ></textarea>
    <input 
      v-else
      :placeholder="placeholder ?? ''"
      :type="inputType"
      :value="modelValue"
      @input="updateModelValue($event.target.value)"
    />
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM8 3C8.19891 3 8.38968 3.07902 8.53033 3.21967C8.67098 3.36032 8.75 3.55109 8.75 3.75V8.25C8.75 8.44891 8.67098 8.63968 8.53033 8.78033C8.38968 8.92098 8.19891 9 8 9C7.80109 9 7.61032 8.92098 7.46967 8.78033C7.32902 8.63968 7.25 8.44891 7.25 8.25V3.75C7.25 3.55109 7.32902 3.36032 7.46967 3.21967C7.61032 3.07902 7.80109 3 8 3ZM8 13C8.26522 13 8.51957 12.8946 8.70711 12.7071C8.89464 12.5196 9 12.2652 9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13Z" fill="#FF693A"/>
    </svg>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
const props = defineProps({
  isError: Boolean,
  inputType: {
    type: String,
    default: 'text'
  },
  placeholder: String,
  textarea: Boolean,
  modelValue: String
})

const emit = defineEmits(['update:modelValue']);

// Function to emit the update:modelValue event with the new value
const updateModelValue = (value) => {
  emit('update:modelValue', value);
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  align-items: center;
  border-radius: 80px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all .3s ease;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  
  input, textarea {
    padding: 14px 18px;
    outline: none;
    border: none;
    background: none;
    color: $white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    transition: all .3s ease;
    &::placeholder {
      opacity: 0.4;
      transition: all .3s ease;
    }
    &:hover {
      &::placeholder {
        opacity: 0.72;
      }
    }
    &:focus, &:active {
      &::placeholder {
        opacity: 0.4;
      }
    }
  }
  &.textarea {
    border-radius: 10px;
  }
  textarea {
    resize: none;
    min-height: 170px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  svg {
    margin-right: 18px;
    display: none;
  }

  &.error {
    border: 1px solid $red;
    input {
      color: $red;
    }
    svg {
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .input {
    textarea {
      min-height: 140px;
    }
    &:focus, &:active, &:hover {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
}
</style>