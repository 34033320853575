<template>
  <swiper
    ref="swiperRef"
    :slides-per-view="slideNum"
    :space-between="26"
    @swiper="onSwiper"
    :modules="[Navigation]"
  >
    <swiper-slide v-for="(slide, index) in prods" :key="index">
      <ProductCard
        :index="index+1"
        :img="slide.img"
        :title="slide.title"
        :text="slide.text"
      ></ProductCard>
    </swiper-slide>
    <div class="controls flex items-center">
      <SliderControl
        @click="goPrev"
        :disabled="swiperInstance?.activeIndex === 0"
        secondary
      ></SliderControl>
      <SliderControl
        @click="goNext"
        btnType="next"
        :disabled="windowWdt < 768 ? swiperInstance?.activeIndex === 4 : swiperInstance?.activeIndex === 3"
        secondary
      ></SliderControl>
    </div>
  </swiper>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { PRODUCTS } from '@/constant/products.js'; 
import ProductCard from "@/components/common/ProductCard.vue";
import SliderControl from "@/components/common/SliderControl.vue";

const props = defineProps({
  slides: Array
})

const prods = ref(PRODUCTS);
const windowWdt = ref(window?.innerWidth);

const swiperInstance = ref(null);
const slideNum = ref(2.5);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};

const changeSlideNum = () => {
  if(windowWdt.value < 768) slideNum.value = 1;
}
changeSlideNum();
window.addEventListener('resize', changeSlideNum);

onUnmounted(() => {
  window.removeEventListener('resize', changeSlideNum);
})
</script>

<style lang="scss" scoped>
.swiper {
  overflow: visible;
  width: 890px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  .swiper-slide {
    width: 280px !important;
  }
  .controls {
    margin-top: 40px;
    gap: 18px;
  }
}
@media (max-width: 768px) {
  .swiper {
    width: 280px;
  }
}
</style>