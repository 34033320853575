<template>
  <div class="home">
    <div class="screen-bg" id="block2">
      <Header />
      <Banner :visible="blocks[1].cnt == 1 && blocks[1].visible" :hide="blocks[1].cnt == 0" :key="blocks[1].cnt" />
    </div>
    <About ref="scroll1" />
    <Feature id="block3" :visibleCnt="blocks[2].cnt" :visible="blocks[2].cnt == 1 && blocks[2].visible" :hide="blocks[2].cnt == 0" :key="blocks[2].cnt"/>
    <Mission ref="scroll2" />
    <Picture />
    <Citation />
    <div class="dark-bg">
      <Services ref="scroll3" id="block4" :visibleCnt="blocks[3].cnt" :visible="blocks[3].cnt == 1 && blocks[3].visible" :hide="blocks[3].cnt == 0" :key="blocks[3].cnt" />
      <Products ref="scroll4" />
    </div>
    <Partnership ref="scroll5" id="block5" :visibleCnt="blocks[4].cnt" :visible="blocks[4].cnt == 1 && blocks[4].visible" :hide="blocks[4].cnt == 0" :key="blocks[4].cnt" />
    <Footer id="block6" :visibleCnt="blocks[5].cnt" :hide="blocks[5].cnt == 0" :key="blocks[5].cnt" />
    <Arrow />
  </div>
</template>

<script setup>
import Header from '@/components/base/Header.vue';
import Banner from '@/components/home/Banner.vue';
import About from '@/components/home/About.vue';
import Feature from '@/components/home/Feature.vue';
import Mission from '@/components/home/Mission.vue';
import Citation from '@/components/home/Citation.vue';
import Services from '@/components/home/Services.vue';
import Picture from '@/components/home/Picture.vue';
import Products from '@/components/home/Products.vue';
import Partnership from '@/components/home/Partnership.vue';
import Footer from '@/components/base/Footer.vue';
import Arrow from '@/components/common/Arrow.vue';

import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useNavigationStore } from '@/store/navigation';
import {isElementVisible} from "@/visibility";

const blocks = ref(Array.from({ length: 6 }, () => ({ id: null, visible: false, cnt: 0 })));

const handleScroll = () => {
  blocks.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (!!element) {
      const isVisible = window?.innerWidth < 768 ? isElementVisible(element, 0) : isElementVisible(element, 100);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt++;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
};

const navigationStore = useNavigationStore()

const scroll1 = ref(null);
const scroll2 = ref(null);
const scroll3 = ref(null);
const scroll4 = ref(null);
const scroll5 = ref(null);

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  if (newValue !== 0 && navigationStore.blockClicked) {
    scrollToBlock(newValue)
  }
})

const getBlockRef = (index) => {
  switch(index) {
    case 'scroll1':
      return scroll1.value;
    case 'scroll2':
      return scroll2.value;
    case 'scroll3':
      return scroll3.value;
    case 'scroll4':
      return scroll4.value;
    case 'scroll5':
      return scroll5.value;
  }
}

const scrollToBlock = (blockIndex) => {
  const blockRef = getBlockRef(blockIndex)
  if (blockRef.$refs[blockIndex]) {
    blockRef.$refs[blockIndex].scrollIntoView({ behavior: 'smooth' })
  }
}

onMounted(() => {
  if (navigationStore.blockIndex !== 0 && navigationStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(navigationStore.blockIndex)
    }, 50)
  }
  blocks.value.forEach((block, index) => {
    block.id = `block${index + 1}`;
  });
  handleScroll();
  window.addEventListener('scroll', handleScroll);
})

onBeforeUnmount(() => {
  navigationStore.setBlockIndex(0)
  navigationStore.setBlockClicked(false)
  window.removeEventListener('scroll', handleScroll)
})

</script>
