function isElementVisible(ref, threshold) {
  if (!ref) {
      return false;
  }

  const element = ref;

  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  
  const isVisible =
      rect.top + threshold <= windowHeight &&
      rect.bottom >= 0 &&
      rect.left <= windowWidth &&
      rect.right >= 0;

  return isVisible;
}

export { isElementVisible };
