<template>
  <form class="flex flex-col" id="formm" @submit.prevent="send">
    <div class="inputs flex items-center">
      <Input placeholder="Your name" v-model="data.name" :isError="isError && data.name.toString().trim().length == 0" />
      <Input placeholder="Your email" v-model="data.email" :isError="isError && !emailRegex.test(data.email)" type="email" />
    </div>
    <Input placeholder="Subject" v-model="data.subject" />
    <Input textarea placeholder="Your message" v-model="data.message"/>
    <div class="action flex items-center justify-between">
      <label class="custom-checkbox flex items-center" :class="{'checked': isChecked}" for="ch-1" @click="isChecked = !isChecked" :key="isChecked" required>
        <input required type="checkbox" id="ch-1" :checked="isChecked"/>
        <span class="checkmark"></span>
        <span class="check-text">I have read and accept the <router-link to="/privacy">Privacy Policy</router-link>.</span>
      </label>
      <Button iconName="message" secondary type="submit">Send A Message</Button>
    </div>
  </form>
  <ResponseModal v-if="isSent" :resType="isSuccess ? 'success' : 'error'" @close="isSent = false" @doubleclose="isSent = false, $emit('close')" />
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import Button from "@/components/ui/Button.vue";
import Input from "@/components/ui/Input.vue";
import ResponseModal from "@/components/ui/ResponseModal.vue";

const data = ref({
  name: '',
  email: '',
  message: '',
  subject: '',
});

const isChecked = ref(false);
const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

watch(isSent, () => {
  if(!isSent.value) resetForm()
})

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key == 'name' && value.toString().trim().length == 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}

const handleInput = (event,propertyName) => {
  data.value[propertyName] = event.target.value;
  if(isError.value) validate();
}

const resetForm = () => {
  data.value = {
    name: "",
    email: "",
    subject: "",
    message: "",
  }
  isSent.value = false;
  isChecked.value = false;
}

const fetchData = (formdata) => {
  const request = {
    method: 'POST',
    body: formdata,
    mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
  .then(response => {
    isSent.value = true;
    isSuccess.value = true;
    // resetForm();
  })
  .catch(e => {
    isSent.value = true;
    isSuccess.value = false;
    // resetForm();
  })
}
const send = () => {
  isError.value = validate();
  if(!isError.value) {
    const formdata = new FormData();
    formdata.append('id', 'Rexus International');
    formdata.append('name', data.value.name);
    formdata.append('email', data.value.email);
    formdata.append('emailto', "info@rexusint.com");
    formdata.append('message', data.value.message);
    formdata.append('subject', data.value.subject);

    fetchData(formdata)
  }
}

onUnmounted(() =>{
  resetForm();
})

</script>

<style lang="scss">
form {
  gap: 20px;
  margin-top: 60px;
  position: relative;
  z-index: 3;
}
.inputs {
  gap: 20px;
  .input {
    width: calc(50% - 10px);
  }
}
.custom-checkbox {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  position: relative;
  height: min-content;
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  .check-text {
    opacity: 0.6;
    color: $white;
    font-family: 'Onest', sans-serif;
    font-size: 14px;
    line-height: 22px;
    a, a:visited, a:focus, a:active {
      color: $white;
    }
  }
  &.checked {
    .check-text {
      opacity: 1;
    }
    &:hover {
      cursor: pointer;
      .checkmark {
        opacity: 0.9;
      }
    }
  }
  & span:nth-child(3) {
    padding-left: 8px;
  }  
  &:not(&.checked):hover {
    cursor: pointer;
    .checkmark {
      border: 1px solid rgba(255, 255, 255, 0.72); 
    }
  }
  & span > span {
    text-decoration: underline;
  }
  input[type="checkbox"]:not(&:checked) + .checkmark {
    display: inline-block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(13px);
    cursor: pointer;
    transition: border .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark {
    border-radius: 6px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/check.svg");
    transition: opacity .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark::before {
    display: block;
  }
}

@media (max-width: 768px) {
  form {
    margin-top: 36px;
    gap: 12px;
    padding: 0 20px 20px 24px;
    .inputs {
      flex-direction: column;
      gap: 12px;
      .input {
        width: 100%;
      }
    }
    .action {
      margin-top: 16px;
      flex-direction: column;
      gap: 22px;
      align-items: flex-start;
      button {
        width: 100%;
      }
    }
  }
}
</style>