<template>
  <nav class="flex justify-between items-center">
    <template v-for="(item, index) in navItems" :key="index">
      <p class="nav-item text-s font-500" @click="handleLinkClick(item)">{{ item.label }}</p>
    </template>
  </nav>
</template>

<script setup>
import { ref } from "vue";
import { NAVS } from "@/constant/nav";
import { useNavigationStore } from '@/store/navigation';
import { useRouter } from 'vue-router'

const navItems = ref(NAVS);

const router = useRouter();
const navigationStore = useNavigationStore();
const handleLinkClick = (link) => {
  if (link.ref) {
    navigationStore.setBlockIndex(link.ref)
    navigationStore.setBlockClicked(true)
    if(router.currentRoute.value.name !== 'home') router.push('/')
  }
}
</script>

<style lang="scss" scoped>
nav {
  color: $white;
  gap: 22px;
  .nav-item {
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      color: $yellow;
    }
  }
}

@media (max-width: 1024px) {
  nav {
    gap: 16px;
  }
}

@media (max-width: 768px) {
  nav {
    display: none;
  }
}
</style>store/navigation