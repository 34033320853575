export const NAVS = [
  {
    label: "About Us",
    ref: "scroll1"
  },
  {
    label: "Mission&Vision",
    ref: "scroll2"
  },
  {
    label: "Services",
    ref: "scroll3",
  },
  {
    label: "Products",
    ref: "scroll4"
  },
  {
    label: "Partnership",
    ref: "scroll5"
  }
]